import { computed } from 'vue'

export default {
  
  computed: {
    remoteDeliverAddress(){
      const { provinceName,cityName,areaName,addr } = this.deliverDetail.extend.customerAddr
      return provinceName+cityName+areaName+addr
    },
    isShowRemoteAddress(){
      return ['4007002','4007003'].includes(this.deliverDetail.deliveryMethod)
    }
  }
}
