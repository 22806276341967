<template>
<div class="pickCarScheduling">
  <van-form ref="schedulingForm" input-align="right" error-message-align="right">
    <div class="common-block" style="padding-top: 12px;">
      <BasicComponent :details="deliverDetail"/>
    </div>
    <div class="common-block">
      <div class="common-title">{{ $t('交付方式') }}</div>
      <div class="vin" style="font-size: 14px;margin-top: 12px;">{{ deliverDetail.deliveryMethod | codeFormat }}</div>
      <div v-if="isShowRemoteAddress" class="vin" style="font-size: 14px;margin-top: 12px;">{{ $t('客户详细地址') }}: {{ remoteDeliverAddress }}</div>
      <div v-if="bookDeliveryTime" class="vin" style="font-size: 14px;margin-top: 12px;">{{ $t('客户预约交付时间') }}: {{ bookDeliveryTimeStr }}</div>
    </div>
    <div class="common-block">
      <div class="common-title no-border">{{ $t('交车时间') }}</div>
      <CalendarLine v-if="defaultDay"
        ref="calendar" 
        :defaultDay="defaultDay" 
        :minDate="minDate" 
        :max-date="maxDate" 
        @changeTime="changeCalendar"/>
      <TimeAppointment
        v-if="deliverConfig.deliveryStartTime"
        ref="timeAPoint"
        :selectTimeIdx="selectTimeIdx"
        :start-time="deliverConfig.deliveryStartTime" 
        :end-time="deliverConfig.deliveryEndTime" 
        :quantity="deliverConfig.deliveryQuantity"
        :bookTimeList="todayBookData"
        @changeTime="onSelect"/>
    </div>
    <van-dialog v-model="isShow" :title="$t('交付工位超限提示')" show-cancel-button 
      :confirm-button-text="$t('确认排程')" 
      :cancel-button-text="$t('另选时间')" 
      confirm-button-color="#EED484"
      @confirm="addBook"> 
      <div class="book-tip-content">
        <div>{{ $t('您选择交付数量和时段') }}:</div>
        <div>{{ $t('交付数量') }}：{{ overBook.orderCount }}</div>
        <div>{{ $t('预约时段') }}：{{ overBook.bookDate }} {{ overBook.bookTimeStart }}-{{ overBook.bookTimeEnd }}</div>
        <div class="content-center">{{ $t('您所选择的交付时间，已经超过门店交付工位上限，工位上限为') }} {{ overBook.configCount }}，{{ $t('当前已预约') }} {{ overBook.bookCount }}</div>
        <div>{{ $t('确认继续在该时段进行交付') }}？</div>
      </div>
    </van-dialog>

    <van-popup v-model="showBatch">
      <BatchOrderSelect v-if="showBatch" :time="selectTime" :current-deliver="deliverDetail" :deliverList="deliverList" @confirm="onBatchSelect" @cancel="showBatch = false"/>
    </van-popup>
    
    <div class="common-footer">
      <van-button class="btn" native-type="button" @click.stop="goBack">{{$t('取消')}}</van-button>
      <van-button class="btn submit" type="submit" @click="onSubmit">{{$t('提交')}}</van-button>
    </div>
  </van-form>
</div>
</template>
<script>
import commonSelector from '@/components/common-selector'
import loading from '@/utils/loading'
import deliveryServices from '@/services/deliveryServices.js'
import dayjs from 'dayjs'
import { dateFormat, padTime } from '@/utils'
import CalendarLine from '@/components/calendar-line/index.vue'
import TimeAppointment from './component/time-appointment.vue'
import BasicComponent from '@/modules/deliverManage/component/basic-component.vue'
import BatchOrderSelect from './component/batch-order-select.vue'
import mixin from './mixin'
import { i18n } from '@/main'
export default {
  name: 'pickCarScheduling',
  components: {
    CalendarLine,TimeAppointment,BasicComponent,BatchOrderSelect
  },
  mixins:[mixin],
  data() {
    return {
      id: '',// 订单/交车单 id
      minDate: dayjs().toDate(),
      maxDate: dayjs().add(3,'month').toDate(),
      selectTimeIdx: [],
      deliverConfig: {
        // 'deliveryQuantity': 5, // 同一时间可接待的数量
        // 'deliveryDuration': 2, // 间隔时间
        // 'deliveryStartTime': '8:00',
        // 'deliveryEndTime': '20:00'
      },
      oldDeliveryEndTime: '',
      oldDeliveryQuantity: 0,
      params: {
        id: '',
        taskStatus: '4004003',
        orderIds: [],
        executionJson: {
          taskCode: 'DELIVERY_BOOK',
          deliveryType: '4007001',
          deliveryTypeName: this.$t('到店提车'),
          bookDate: '', // 预约日期
          bookTimeStart: '', // 预约开始时间
          bookTimeEnd: '', // 预约结束时间
          arriveFlag: undefined, // 标识客户是否到店 1是0否 
        },
      },
      todayBookData: [],
      fromFollow: false,
      isShow: false,
      overBook:{
        bookCount: '',
        configCount: '',
        bookDate: '',
        bookTimeEnd: '',
        bookTimeStart: '',
        selectTimeIdx: ''
      },
      bookDeliveryTime: '', // 客户预约时间
      deliverDetail: {},
      showBatch: false,
      selectTime: '',
      clickTime: '',
      clickIdx: -1,
      defaultDay: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === '/deliver-follow') {
        vm.fromFollow = true
      }
    })
  },
  computed: {
    bookDeliveryTimeStr(){
      const val = this.bookDeliveryTime
      let str
      const h = dayjs(val).format('HH')
      if (h>12){
        str = dayjs(val).format('YYYY-MM-DD') + ` ${this.$t('下午')}`
      } else {
        str = dayjs(val).format('YYYY-MM-DD') + ` ${this.$t('上午')}`
      }
      return str
    }
  },
  async created(){
    // 获取门店交车配置
    await this.getDeliverConfig()
    const { bookDate, bookTimeEnd, bookTimeStart } = this.$route.query
    // 预约时间晚于或等于当前日期，如果预约时间早于当前日期则还是默认选中当前日期
    if (bookDate && (dayjs().isBefore(dayjs(bookDate),'date') || dayjs().isSame(dayjs(bookDate),'date'))) {
      this.params.executionJson.bookDate = bookDate
      this.params.executionJson.bookTimeStart = bookTimeStart
      this.params.executionJson.bookTimeEnd = bookTimeEnd
    } else {
      this.params.executionJson.bookDate = dayjs().format('YYYY-MM-DD')
    }
    // 设置日历
    this.defaultDay = this.params.executionJson.bookDate
    // 获取指定日期的交车情况
    this.getTodayBookData(this.params.executionJson.bookDate)
  },
  mounted() {
    // 回显车辆/车主信息
    const { id, taskId, arriveFlag,bookDeliveryTime } = this.$route.query
    this.id = id
    // 获取交车单详情
    this.getDeliverDetail()
    this.bookDeliveryTime = bookDeliveryTime
    
    // 客户是否到店的标识
    this.params.executionJson.arriveFlag = arriveFlag
    // 初始化数据
    this.params.id = taskId
  },
  methods: {
    // 获取交车单详情
    async getDeliverDetail() {
      const res = await deliveryServices.deliveryDetail({ id: this.id })
      this.$set(this,'deliverDetail',res)
    },
    async getDeliverConfig(){
      loading.showLoading()
      const res = await deliveryServices.getDeliverConfig()
      loading.hideLoading()
      this.$set(this,'deliverConfig',res)
      this.oldDeliveryEndTime = this.deliverConfig.deliveryEndTime
      this.oldDeliveryQuantity = this.deliverConfig.deliveryQuantity
    },
    // 修改日历
    changeCalendar(date){
      this.params.executionJson.bookDate = date
      this.getTodayBookData(date)
    },
    async onSelect(time,idx){
      // 查询同批次的订单
      if ([1,2,5].includes(this.deliverDetail.orderType)){
        const res = await deliveryServices.getBatchOrder({ orderId: this.id, type:1 })
        if (res.length){
          this.deliverList = res
          this.showBatch = true
          this.selectTime = `${this.$refs.calendar.activeDay} ${time}-${dayjs(`${this.$refs.calendar.activeDay} ${time}:00`).add(this.deliverConfig.deliveryDuration,'hour').format('HH:mm')}`
        } else {
          // 没有同批量数据则直接排程
          this.validateTime(time,idx)
        }
        this.clickTime = time 
        this.clickIdx = idx 
      } else {
        this.validateTime(time,idx)
      }
    },
    onBatchSelect(selectIds){
      this.$set(this.params,'orderIds',selectIds)
      this.validateTime(this.clickTime,this.clickIdx,selectIds.length+1)
    },
    async validateTime(time,idx,orderCount=1){
      const params = {
        date: this.$refs.calendar.activeDay,
        end: dayjs(`${this.$refs.calendar.activeDay} ${time}:00`).add(this.deliverConfig.deliveryDuration,'hour').format('HH:mm'),
        count: orderCount
      }
      const { bookCount,configCount,isOutSize } = await deliveryServices.isTimeConflict(params)

      if (!isOutSize){
        this.params.executionJson.bookDate = this.$refs.calendar.activeDay
        this.params.executionJson.bookTimeStart = time
        this.params.executionJson.bookTimeEnd = params.end
        // 色块选择
        if (orderCount===1){
          this.selectTimeIdx = [idx]
        } else {
          const tempIdx = []
          this.$refs.timeAPoint.timeAreaList.forEach((e,idx)=>{
            if (e.time===time){
              tempIdx.push(idx)
            }
          })
          this.selectTimeIdx = tempIdx.slice(this.deliverConfig.deliveryQuantity - orderCount)
        }
      } else {
        this.isShow = true
        this.overBook = {
          orderCount,
          bookCount,
          configCount,
          bookDate: this.$refs.calendar.activeDay,
          bookTimeStart: time,
          bookTimeEnd: params.end,
          selectTimeIdx: idx
        }
      }
    },
    addBook(){
      const { bookCount, orderCount } = this.overBook
      // 当前已有的工位数
      const configCount = this.deliverConfig.deliveryQuantity
      // 增加工位数量  已预约+待排程-当前已有的工位数
      const plusCount = Number(bookCount)+orderCount-configCount
      const count = this.deliverConfig.deliveryQuantity + plusCount
      
      this.$set(this.deliverConfig,'deliveryQuantity',count)

      this.params.executionJson.bookDate = this.overBook.bookDate
      this.params.executionJson.bookTimeStart = this.overBook.bookTimeStart
      this.params.executionJson.bookTimeEnd = this.overBook.bookTimeEnd

      const tempIdx = []
      setTimeout(() => {
        // 获取选中时间的索引值
        this.$refs.timeAPoint.timeAreaList.forEach((e,idx)=>{
          if (e.time===this.overBook.bookTimeStart){
            tempIdx.push(idx)
          }
        })
        this.selectTimeIdx = tempIdx.slice(count - orderCount)
      }, 500)
    },
    // 选择交车方式
    async selectField(field, dictType, multiple = false) {
      const _obj = {
        field,
        dictType,
        multiple,
      }
      commonSelector(_obj).then(res => {
        this.params.executionJson[field] = res.dictCode
        this.params.executionJson.deliveryTypeName = res.dictName
      })
    },
    // 同一时间最多需要的工位数
    getMaxQuantity(bookList){
      const map = {}
      const find = (list,time)=>{
        let count = 0
        list.forEach(e=>{
          const [startTime] = e.bookTime.split('-')
          const target = dayjs(dayjs().format(`YYYY-MM-DD ${time}:00`))
          const start = dayjs(dayjs().format(`YYYY-MM-DD ${startTime}:00`))
          // 开始时间在指定时间的前后两个小时则算同一时间
          if (start.isAfter(target.subtract(2,'hour'))&&start.isBefore(target.add(2,'hour'))){
            count++
          }
        })
        map[time] = count
      }
      bookList.forEach(item=>find(bookList,item.bookTime.split('-')[0]))
      return Math.max(...Object.values(map),this.oldDeliveryQuantity)
    },
    // 获取最晚时间
    getMaxEndTime(bookList){
      let maxTime = this.oldDeliveryEndTime
      bookList.forEach(({ bookTime })=>{
        const endTime = bookTime.split('-')[1]
        maxTime = this.judgeTime(maxTime,endTime)
      })
      return maxTime
    },
    judgeTime(time = '00:00',time2='23:59'){
      const t = dayjs(`2024-06-06 ${time}:00`)
      const t2 = dayjs(`2024-06-06 ${time2}:00`)
      if (t.isBefore(t2)){
        return time2
      } else {
        return time
      }
    },
    // 获取指定日期的预约数量 time 2024-02-24
    async getTodayBookData(time) {
      
      const res = await deliveryServices.deliveryBookDay({ date: time })
      // 判断预约的工位数 是否超过 设置的工位数，超过了按照预约数量来  不然排不下
      const maxQuantity = this.getMaxQuantity(res)
      // 判断预约时间是否超过最晚营业时间，超过了则按预约时间来
      const maxTime = this.getMaxEndTime(res)
      this.$set(this,'deliverConfig',{
        ...this.deliverConfig,
        deliveryQuantity: maxQuantity,
        deliveryEndTime: maxTime
      })
      setTimeout(() => {
        this.todayBookData = res || []
      }, 500)

      // if (maxQuantity > this.deliverConfig.deliveryQuantity){
      //   this.$set(this.deliverConfig,'deliveryQuantity',maxQuantity)
      //   setTimeout(() => {
      //     this.todayBookData = res || []
      //   }, 500)
      // } else {
      //   this.todayBookData = res || []
      // }
    },
    goBack() {
      this.$router.go(-1)
    },
    async onSubmit() {
      if (this.fromFollow) {

      }
      if (!this.params.executionJson.bookTimeStart || !this.params.executionJson.bookTimeEnd){
        return this.$toast.fail(this.$t('请选择交车时间'))
      }
      const orderIds = [...this.params.orderIds,this.id]
      this.$set(this.params,'orderIds',orderIds)
      loading.showLoading()
      
      const res = await deliveryServices.deliveryExecution(this.params)
      this.$toast.success(this.$t('操作成功'))
      if (res.code === 0) {
        setTimeout(() => {
          if (this.fromFollow) {
            this.$router.push({
              path: '/deliver-follow',
              query: {
                id: this.$route.query.id,
                bookDate: this.params.executionJson.bookDate,
                bookTimeEnd: this.params.executionJson.bookTimeEnd,
                bookTimeStart: this.params.executionJson.bookTimeStart
              }
            })
          } else {
            this.$router.go(-1)
          }
        }, 1000)
      } else {
        this.$toast(res.msg)
      }
    }
  },
}
</script>
<style lang="less" scoped>
.pickCarScheduling{
  padding: 16px;
  padding-bottom: 96px;
  .common-block{
    padding: 0 12px;
    font-size: 14px;
    .title{
      display: flex;
      height: 48px;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(13, 23, 26, 0.05);
      justify-content: space-between;
      font-size: 16px;
      position: relative;
    }
    &.top{
      padding: 12px;
    }
    
    &:not(:last-of-type){
      margin-bottom: 16px;
    }
    .deliverInfo-t{
      display: flex;
      flex-direction: column;
      font-size: 16px;
      margin-top: 12px;
      .deliverInfo-t-l{
        display: flex;
        height: 16px;
        line-height: 16px;
        margin-bottom: 8px;
        .deliverInfo-t-l-id{
          max-width: calc(100% - 48px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .deliverInfo-t-l-status{
          width: 40px;
          margin-left: 8px;
          padding-left: 8px;
          position: relative;
          color: #B9921A;
          box-sizing: border-box;
        }
        .deliverInfo-t-l-status::before{
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 70%;
          background-color: #D9D9D9;
          transform: translateY(-50%);
        }
      }
      .deliverInfo-t-r{
        display: flex;
        justify-content: space-between;
        color: #0D171A;
        line-height: 16px;
        font-size: 13px;
        margin-bottom: 8px;
        .amount{
          color: #E4002C;
        }
      }
    }
    .vin{
      padding-bottom: 8px;
      font-size: 13px;
    }
  }
}

/deep/.van-dialog__content{
  .book-tip-content{
    padding: 12px;
    font-size: 14px;
    .content-center{
      margin: 12px 0;
    }
  }
}
</style>
