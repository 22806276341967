<template>
<div class="time-appointment">
    <div class="time">
        <div v-for="time in timeList" :key="time" class="row">{{ time }}</div>
    </div>
    <div class="book-grid" :style="{'grid-template-columns':`repeat(${quantity}, 72px)`,'grid-template-rows':`repeat(${timeList.length}, 48px)`}">
        <div v-for="(e,idx) in timeAreaList" :id="idx" :key="idx"
            :data-dynamic-content="getContent(e)" 
            :class="['item',selectIdx.includes(idx)?'selected':'',isDisabled(e)?'disabled':'']" 
            @click="onSelectTime(e,idx)">
            <!-- {{ e.time }}-{{ e.area  }} -->
        </div>
    </div>
</div>
</template>
<script>
import dayjs from 'dayjs'

export default {
  props: {
    startTime: {
      type: String,
      default: '08:00'
    },
    endTime:{
      type: String,
      default: '18:00'
    },
    duration: {
      type: Number,
      default: 2 // 间隔时间，单位小时
    },
    quantity:{
      type: Number,
      default: 5 // 同一时间的可预定的数量
    },
    bookTimeList: {
      type: Array,
      default: ()=>([]) // 已经被预约的时间段
    },
    selectTimeIdx:{
      type: Array,
      default: ()=>[]
    }
    // selectTimeIdx:{
    //   type: Number,
    //   default: -1
    // }
  },
  data(){
    return {
      timeList: [],
      timeAreaList: [], 
      selectIdx: [],
      disabledObjMap: [],
      disabledStartTimeArea: {}
    }
  },
  watch:{
    bookTimeList: {
      handler(val){ // [{bookTime,name},{}]
        if (!this.timeAreaList.length) return
        this.bookTimeDisplay()
      },
      deep: true
    },
    selectTimeIdx:{
      handler(val){ // [{bookTime,name},{}]
        this.selectIdx = val
      }
    },
    quantity: {
      handler(val){
        this.getTimeAreaList()
      }
    },
    endTime: {
      handler(){
        this.generateTimeArray(this.startTime,this.endTime)
      }
    }
  },
  mounted(){
    // 生成时间表
    this.generateTimeArray(this.startTime,this.endTime)
    this.getTimeAreaList()
    this.bookTimeDisplay()
  },
  methods: {
    getContent({ time,area }){
      const startTimeList = this.disabledStartTimeArea[area]
      if (!startTimeList?.length) return ''
      const idx = startTimeList.indexOf(time)
      return idx===-1?'':this.disabledObjMap[area][idx].userName
    },
    isDisabled({ time,area }){
      const startTimeList = this.disabledStartTimeArea[area]
      if (!startTimeList?.length) return false
      return startTimeList.includes(time)
    },
    // 生成时刻表
    generateTimeArray(startTime, endTime) {
      const result = []
      const [startHour, startMinute] = startTime.split(':').map(Number)
      const [endHour, endMinute] = endTime.split(':').map(Number)

      let currentHour = startHour
      let currentMinute = startMinute

      while (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute)) {
        const formattedHour = currentHour < 10 ? `0${currentHour}` : `${currentHour}`
        const formattedMinute = currentMinute === 0 ? '00' : `${currentMinute}`
        result.push(`${formattedHour}:${formattedMinute}`)

        // 增加 30 分钟
        currentMinute += 30
        if (currentMinute === 60) {
          currentMinute = 0
          currentHour += 1
        }
      }
      this.timeList = result
    },
    // 时刻-位置列表
    getTimeAreaList(){
      const total = this.quantity*this.timeList.length
      const arr = []
      for (let index = 0; index < total; index++) {
        arr.push({
          time: this.timeList[Math.floor(index/this.quantity)],
          area: index % this.quantity,
          disabledContent: ''
        })
      }
      // console.log('arr:',arr)
      this.timeAreaList = arr
    },
    sortTimeArray(timeArray) {
      return timeArray.sort((a, b) => {
        const timeA = dayjs(`2024-02-27 ${a}:00`).toDate()
        const timeB = dayjs(`2024-02-27 ${b}:00`).toDate()
        return timeA - timeB 
      })
    },
    // 添加并排序
    addDisableStartTime(area,startTime,endTime,userName){
      const disabledStartTimeArea = this.disabledStartTimeArea[area]
      if (!disabledStartTimeArea.length){
        disabledStartTimeArea.push(startTime)
        this.disabledObjMap[area].push({ startTime,endTime,userName })
      } else {
        disabledStartTimeArea.push(startTime)
        // 按时间从早到晚排序
        const result = this.sortTimeArray(disabledStartTimeArea)
        const idx = result.indexOf(startTime)
        this.disabledObjMap[area].splice(idx,0,{ startTime,endTime,userName })
      }
      // console.log('===1area:',area,this.disabledStartTimeArea[area])
      // console.log('===2area:',area,this.disabledObjMap[area])
    },
    // 时间是否存在冲突
    isOverlap(startTimeA, endTimeA) {
      const startA = dayjs(`2024-02-27 ${startTimeA}:00`)
      const endA = startA.add(2,'hour')
      const startB = dayjs(`2024-02-27 ${endTimeA}:00`)
      const endB = startB.add(2,'hour')

      return (startA.isBefore(endB)&& endA.isAfter(startB) )
    } ,
    // 回显已预约的时间
    bookTimeDisplay(){
      this.$set(this,'disabledStartTimeArea',{})
      // 初始化
      for (let index = 0; index < this.quantity; index++) {
        this.$set(this.disabledStartTimeArea,index,[])
        this.$set(this.disabledObjMap,index,[])
      }
      // 遍历已预约时间，排在合适的位置
      for (let index = 0; index < this.bookTimeList.length; index++) {
        const { bookTime,userName } = this.bookTimeList[index]
        const arr = bookTime.split('-')
        const [bookStartTime,bookEndTime] = arr
        // 遍历接待车位
        for (let area = 0; area < this.quantity; area++) {
          // console.log('area:',area)
          // 预约时间有冲突
          if (this.disabledStartTimeArea[area].includes(bookStartTime)){
            // console.log('有冲突')
            continue
          }
          // 车位无预约，则直接预约
          if (!this.disabledStartTimeArea[area].length){
            // console.log('空车位')
            this.addDisableStartTime(area,bookStartTime,bookEndTime,userName)
            break
          } else {
            // console.log('area:',`${area}已有预约`)
            // 判断是否有空余时间可插入
            const disabledStartTimeArea = this.disabledStartTimeArea[area]
            let flag = true
            for (let idx = 0; idx < disabledStartTimeArea.length; idx++) {
              const e = disabledStartTimeArea[idx]
              if (this.isOverlap(e,bookStartTime)){ flag=false }
            }
            if (flag){
              this.addDisableStartTime(area,bookStartTime,bookEndTime,userName)
              break
            }
          }
        }
      }
    },
    
    // 选择时间
    onSelectTime({ time },idx){
      this.$emit('changeTime',time,idx)
    }
  }
}
</script>
<style lang="less" scoped>
.time-appointment{
    width: 100%;
    display: flex;
    .time{
        width: 60px;
        .row{
            height: 48px;
            font-family: HYQiHei, HYQiHei;
            font-weight: normal;
            font-size: 14px;
            color: #0D171A;
            line-height: 48px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            border-bottom: 1px solid rgba(13,23,26,0.05);
        }
    }
    .book-grid{
        flex: 1;
        display: grid;
        overflow-x: auto;
        .item{
            border: 1px dashed rgba(13,23,26,0.05);
            cursor: pointer;
            user-select: none;
            z-index: 1;
        }
        .selected{
            position: relative;
            &::after{
                position: absolute;
                top: 0;
                left: 0;
                width: 72px;
                height: 240px; //5 * 48
                content: '';
                background: rgba(238, 212, 132, 1);
                z-index: 2;
            }
        }
        .disabled{
            position: relative;
            z-index: 1;
            &::after{
              box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
                margin: -1px;
                padding-top: 50%;
                width: 72px;
                height: 240px;
                border: 1px solid rgba(13, 23, 26, 0.05);
                content: attr(data-dynamic-content); //取data-dynamic-content属性值展示
                overflow-wrap: break-word;
                background: #E5E5E5;
                border: 1px solid #cdcdcd;
                border-radius: 10px;
            }
        }
    }
}
</style>